export default {
  proxy_path: "https://vgl8yws7p7.execute-api.us-east-1.amazonaws.com/api",
  proxy_path_geojson_api: "https://h242dwgbma.execute-api.us-east-1.amazonaws.com/api",
  google_api_key: "AIzaSyDVZjTV9W6b88zZ1cSax2y9SVBUckHtjCo",
  main_company_term_id: "191",
  crm_base_url: "riotinto.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.riotinto.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.riotinto.forwoodsafety.com",
    APP_CLIENT_ID: "48kidrml8msr6cjep9eg38ojqt",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://1ja5ewxqq7.execute-api.us-east-1.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.riotinto.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
